<div fxLayout="row wrap" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
     cdkDragBoundary=".cdk-overlay-container" cdkDragHandle class="dialog-drag">
     <h2 class="user-widget-heading" *ngIf="isEdit === 'false' && !isProvider">Add User</h2>
     <h2 class="user-widget-heading" *ngIf="isEdit === 'true' && !isProvider">Edit User</h2>
     <h2 class="user-widget-heading" *ngIf="isEdit === 'false' && isProvider">Add Provider</h2>
     <h2 class="user-widget-heading" *ngIf="isEdit === 'true' && isProvider">Edit Provider</h2>
    <div fxFlex></div>
  <button mat-icon-button matTooltip="Click here to close the popup"
          (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div fxLayout="row wrap" fxLayoutAlign="center">
</div>
<form [formGroup]="userForm" autocomplete="off" *ngIf="userForm">
  <div fxLayout="row wrap">
    <div class="firstname-input" *ngIf="!isProvider">
      <ejs-textbox placeholder="First Name*" floatLabelType="Auto" formControlName="firstName"></ejs-textbox>
      <mat-error *ngIf="userForm.get('firstName').hasError('required') && userForm.get('firstName').touched">
        <small>Required</small>
      </mat-error>
    </div>
    <div class="firstname-input" *ngIf="!isProvider">
      <ejs-textbox placeholder="Last Name*" floatLabelType="Auto" formControlName="lastName"></ejs-textbox>
      <mat-error *ngIf="userForm.get('lastName').hasError('required') && userForm.get('lastName').touched">
        <small>Required</small>
      </mat-error>
    </div>
    <div class="firstname-input" *ngIf="(isProvider && isEdit == 'true') || !isProvider">
      <ejs-textbox placeholder="Email*" floatLabelType="Auto" formControlName="email" [readonly]="isProvider && isEdit == 'true'" pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"></ejs-textbox>
      <mat-error *ngIf="userForm.get('email').hasError('required') && userForm.get('email').touched">
        <small>Required</small>
      </mat-error>
      <small *ngIf="userForm.get('email').errors?.email && userForm.get('email').touched" class="mat-text-warn">
        Please Enter a Valid Address.</small>
    </div>
    <div class="firstname-input" *ngIf="!isProvider">
      <ejs-textbox placeholder="Phone Number" floatLabelType="Auto" formControlName="phoneNumber" min="0"></ejs-textbox>
    </div>
    <div *ngIf="isProvider">
      <ejs-dropdownlist #loginTypeDropdown [dataSource]='loginTypeList' floatLabelType="Auto" placeholder="Login Type*"
                        formControlName="loginType" [fields]="loginTypeFields" [sortOrder]="generalSortOrder" [allowFiltering]='true'
                        [filterBarPlaceholder]="'Search Login Type'" [popupHeight]='height' class="timezone-dropdown" [readonly]="true">
      </ejs-dropdownlist>
      <mat-error *ngIf="userForm.get('loginType').hasError('required') && userForm.get('loginType').touched">
        <small>Required</small>
      </mat-error>
    </div>
    <div>
      <ejs-dropdownlist #timezoneDropdown [dataSource]='timezoneList' floatLabelType="Auto"
                        placeholder="Timezone*" formControlName="timezone" [fields]="timezoneFields"
                        [sortOrder]="generalSortOrder" (filtering)="searchTimezone($event)"
                        [allowFiltering]='true' [filterBarPlaceholder]="'Search Timezone'"
                        [popupHeight]='height' class="timezone-dropdown" [itemTemplate]="itemTemplate">
        <ng-template #noRecordsTemplate>
          <span class='norecord'>Loading Timezones...</span>
        </ng-template>
        <ng-template #itemTemplate="" let-data="">
          <span [title]="data.key">{{ data.key }}</span>           
        </ng-template>
      </ejs-dropdownlist>
      <mat-error *ngIf="userForm.get('timezone').hasError('required') && userForm.get('timezone').touched">
        <small>Required</small>
      </mat-error>
    </div>
    <div *ngIf="!isProvider">
      <ejs-dropdownlist [dataSource]='loginTypeList' floatLabelType="Auto" placeholder="Login Type*"
                        formControlName="loginType" [fields]="loginTypeFields" [sortOrder]="generalSortOrder" [allowFiltering]='true'
                        [filterBarPlaceholder]="'Search Login Type'" [popupHeight]='height' class="timezone-dropdown">
      </ejs-dropdownlist>
      <mat-error *ngIf="userForm.get('loginType').hasError('required') && userForm.get('loginType').touched">
        <small>Required</small>
      </mat-error>
      <mat-error *ngIf="userForm.get('loginType').hasError('invalidType') && userForm.get('loginType').touched">
        <small>{{userForm.get('loginType').errors?.invalidType}}</small>
      </mat-error>
    </div>
    <div class="firstname-input">
      <ejs-textbox placeholder="License" floatLabelType="Auto" formControlName="license" appInputRestriction></ejs-textbox>
    </div>
    <div *ngIf="!isProvider">
      <ejs-dropdownlist #supervisorDropdown [dataSource]='supervisorList' [fields]='supervisorFields'
                        floatLabelType="Auto" [query]='supervisorQuery' [itemTemplate]="itemTemplate"
                        placeholder="Supervisor" formControlName="supervisor" (filtering)="searchSupervisor($event)"
                        (change)="onSupervisorSelect($event)" [sortOrder]="generalSortOrder"
                        [allowFiltering]='true' [filterBarPlaceholder]="'Search Supervisor'"
                        [popupHeight]='height' class="supervisor-dropdown">
        <ng-template #noRecordsTemplate>
          <span class='norecord'>Loading Supervisor...</span>
        </ng-template>
        <ng-template #itemTemplate="" let-data="">
          <span [title]="data.name">{{ data.name }}</span>           
        </ng-template>
      </ejs-dropdownlist>
    </div>
    <div fxLayoutAlign="end" *ngIf="!isProvider">
      <ejs-checkbox label="Mark as Supervisor" cssClass="e-small" formControlName="isSupervisor"
                    [(ngModel)]="isMarkAsSupervisorChecked"
                    (change)="onMarkAsSupervisorCheckboxChange($event)"></ejs-checkbox>
    </div>
    <div fxLayoutAlign="end" class="employee-type-section" *ngIf="showEmployeeTypeRadioButtons === true && !isProvider">
      <span>Employee Type*:</span>
      <ejs-radiobutton *ngFor="let item of listItem" [value]="item.value" formControlName="typeOfUser"
                       label="{{item.name}}" [disabled]="forceInternalUser"
                       (change)="getSelectedEmployeeType($event)"></ejs-radiobutton>
    </div>
    <div *ngIf="!isProvider">
      <ejs-dropdownlist #majorRoleDropdown [dataSource]='majorRoleList' [fields]='fields' floatLabelType="Auto" *ngIf="showMajorRoleRoleDropdown === true"
                        placeholder="Major Role" formControlName="majorRole" [showClearButton]="true"
                        (filtering)="searchMajorRole($event)" [itemTemplate]="itemTemplate"
                        [popupHeight]='height' class="supervisor-dropdown" (change)="onMajorRoleSelection($event)">
        <ng-template #noRecordsTemplate>
          <span class='norecord'>Loading Major Role...</span>
        </ng-template>
        <ng-template #itemTemplate="" let-data="">
          <span [title]="data.name">{{ data.name }}</span>           
        </ng-template>
      </ejs-dropdownlist>
    </div>
  </div>

  <div fxLayout="column">
    <ng-container formArrayName="clients">
      <ng-container *ngFor="let client of userForm['controls'].clients['controls']; let i = index">
        <div fxLayout="column" formGroupName="{{i}}">
          <div fxLayout="row">
            <div [ngClass]="{'add-button': isUserTypeInternal === false}">
              <button mat-icon-button (click)="addNewClientSection()" class="add-client-section-button"
                      *ngIf="isUserTypeInternal === true" [disabled]="isAddNewClientIconDisabled === true">
                <mat-icon [ngClass]="{'disable-button': isAddNewClientIconDisabled === true}">add</mat-icon>
              </button>
            </div>
            <ejs-dropdownlist #clientDropdown [dataSource]='clientList' [fields]='fields' floatLabelType='Auto'
                              placeholder="Client" formControlName="id" [sortOrder]="generalSortOrder"
                              [allowFiltering]='true' [filterBarPlaceholder]="'Search Client'"
                              (select)="getProjectListOfSelectedClient($event, client,i)" [itemTemplate]="itemTemplate"
                              [popupHeight]='height' class="client-dropdown" (filtering)="searchClient($event)" [readonly]="isProvider == true && isEdit == 'true'">
              <ng-template #noRecordsTemplate>
                <span class='norecord'>Loading Client...</span>
              </ng-template>
              <ng-template #itemTemplate="" let-data="">
                <span [title]="data.name">{{ data.name }}</span>           
              </ng-template>
            </ejs-dropdownlist>
            <button mat-icon-button *ngIf="i > 0" (click)="removeClientSection(i)" [disabled]="isRemoveClientIconDisabled == true"
                    class="remove-client-section-button">
              <mat-icon [ngClass]="{'disable-button': isRemoveClientIconDisabled === true}">remove</mat-icon>
            </button>
          </div>
          <div *ngIf="isProvider">
            <ejs-dropdownlist [dataSource]='client.value.providersList' [fields]='providerFields' floatLabelType='Auto'
                            placeholder="Provider" formControlName="providers" [sortOrder]="generalSortOrder"
                            [allowFiltering]='true' [filterBarPlaceholder]="'Search Provider'" style="margin-left:40px;"
                            [popupHeight]='height' class="provider-dropdown" [readonly]="isEdit == 'true'">
            </ejs-dropdownlist>
          </div>
          <div>
            <div fxLayout="row" *ngIf="isEdit === 'true'">
              <button mat-icon-button *ngIf="i > 0 && clientToBeDisable == client.value.id && activeClientRole === 5" (click)="removeClientSection(i)"
                      [disabled]="clientToBeDisable == client.value.id"
                      class="remove-client-section-button">
                <mat-icon [ngClass]="{'disable-button': clientToBeDisable == client.value.id}">remove</mat-icon>
              </button>
                <button mat-icon-button *ngIf="i > 0 && clientToBeDisable !== client.value.id" (click)="removeClientSection(i)" [disabled]="isRemoveClientIconDisabled === true"
                      class="remove-client-section-button">
                <mat-icon [ngClass]="{'disable-button': isRemoveClientIconDisabled === true}">remove</mat-icon>
              </button>
            </div>
            <div fxLayout="row" *ngIf="isEdit === 'false'">
              <button mat-icon-button *ngIf="i > 0" (click)="removeClientSection(i)" [disabled]="isRemoveClientIconDisabled === true"
                      class="remove-client-section-button">
                <mat-icon [ngClass]="{'disable-button': isRemoveClientIconDisabled === true}">remove</mat-icon>
              </button>
            </div>

          </div>
          <div *ngIf="!isProvider">
            <ejs-multiselect #adminPermissionDropdown [dataSource]='adminPermissionList' formControlName="permissions" floatLabelType='Auto'
                             placeholder="Admin Permission" [fields]='fields' [sortOrder]="generalSortOrder"
                             [mode]='adminPermissionMode' [popupHeight]='height' [showDropDownIcon]='true' [showClearButton]="false"
                             [allowFiltering]='true' [filterBarPlaceholder]="'Search Admin Permission'"
                             *ngIf="showAdminPermissionDropdown === true" (filtering)="searchAdminPermission($event)"
                             showSelectAll='true' class="admin-permission-dropdown" [showClearButton]="true">
              <ng-template #noRecordsTemplate>
                <span class='norecord'>Loading Admin Permissions...</span>
              </ng-template>
              <ng-template #itemTemplate="" let-data="">
                <span [title]="data.name">{{ data.name }}</span>           
              </ng-template>
            </ejs-multiselect>
          </div>
          <div fxLayout="column" *ngIf="isMajorRoleSelected === false">
            <ng-container formArrayName="projects" *ngIf="client['controls'].projects['controls']">

              <!-- Project section -->
              <ng-container *ngFor="let project of client['controls'].projects['controls']; let j=index">
                <div fxLayout="column" fxLayoutAlign="center" formGroupName="{{j}}">
                  <div fxLayout="row">
                    <div fxLayout="row" *ngIf="isEdit === 'true'">
                      <button mat-icon-button *ngIf="clientToBeDisable == client.value.id && activeClientRole === 5"
                              (click)="addNewProjectSection(i)" class="add-project-section-button" [disabled]="clientToBeDisable == client.value.id">
                        <mat-icon [ngClass]="{'disable-button': clientToBeDisable == client.value.id}">add</mat-icon>
                      </button>
                        <button mat-icon-button *ngIf="clientToBeDisable !== client.value.id"
                                (click)="addNewProjectSection(i)" class="add-project-section-button" [disabled]="isAddNewProjectIconDisabled === true">
                          <mat-icon [ngClass]="{'disable-button': isAddNewProjectIconDisabled === true}">add</mat-icon>
                        </button>
                    </div>
                    <div fxLayout="row" *ngIf="isEdit === 'false'">
                        <button mat-icon-button
                                (click)="addNewProjectSection(i)" class="add-project-section-button" [disabled]="isAddNewProjectIconDisabled === true">
                          <mat-icon [ngClass]="{'disable-button': isAddNewProjectIconDisabled === true}">add</mat-icon>
                        </button>
                    </div>

                    <div fxLayout="column">
                      <ejs-multiselect #projectDropdown [dataSource]="client.value.projectList" placeholder='Projects' mode="CheckBox"
                        width="180" popupWidth="180" formControlName="ids" showSelectAll='true' [showDropDownIcon]='true'
                        [showClearButton]="true" [fields]='fields' floatLabelType='Always' [allowFiltering]='true'
                        filterBarPlaceholder="Search Project" [sortOrder]="generalSortOrder" (filtering)="searchProject($event)" (change)="onProjectSelection($event, project, client.value.projectList)">
                        <ng-template #noRecordsTemplate>
                          <span class='norecord'>Loading Projects...</span>
                        </ng-template>
                        <ng-template #itemTemplate="" let-data="">
                          <span [title]="data.name">{{ data.name }}</span>           
                        </ng-template>
                      </ejs-multiselect>
                    </div>

                    <div fxLayout="row" *ngIf="isEdit === 'true'">
                      <button mat-icon-button *ngIf="j > 0 && clientToBeDisable == client.value.id && activeClientRole === 5" (click)="removeProjectSection(i, j)" class="remove-project-section-button"
                              [disabled]="clientToBeDisable == client.value.id && activeClientRole === 5">
                        <mat-icon [ngClass]="{'disable-button': clientToBeDisable == client.value.id && activeClientRole === 5}">remove</mat-icon>
                      </button>
                      <button mat-icon-button *ngIf="j > 0 && clientToBeDisable !== client.value.id" (click)="removeProjectSection(i, j)" class="remove-project-section-button"
                              [disabled]="isRemoveProjectIconDisabled === true">
                        <mat-icon [ngClass]="{'disable-button': isRemoveProjectIconDisabled === true}">remove</mat-icon>
                      </button>
                    </div>
                    <div fxLayout="row" *ngIf="isEdit === 'false'">
                      <button mat-icon-button *ngIf="j > 0" (click)="removeProjectSection(i, j)" class="remove-project-section-button"
                              [disabled]="isRemoveProjectIconDisabled === true">
                        <mat-icon [ngClass]="{'disable-button': isRemoveProjectIconDisabled === true}">remove</mat-icon>
                      </button>
                    </div>
                  </div>
                  <div *ngIf="!isProvider">
                    <ejs-dropdownlist #fteStatusDropdown [dataSource]='fteStatusList' [fields]='fields' floatLabelType='Auto'
                                      width="160" (filtering)="searchFteStatus($event)" [itemTemplate]="itemTemplate"
                                      placeholder="FTE Status" formControlName="fteStatus" [sortOrder]="generalSortOrder"
                                      [popupHeight]='height' class="fte-status-dropdown">
                      <ng-template #noRecordsTemplate>
                        <span class='norecord'>Loading Fte Status...</span>
                      </ng-template>
                      <ng-template #itemTemplate="" let-data="">
                        <span [title]="data.name">{{ data.name }}</span>           
                      </ng-template>
                    </ejs-dropdownlist>
                  </div>
                  <div *ngIf="!isProvider">
                    <ejs-dropdownlist #roleDropdown [dataSource]='roleList' [fields]='fields' floatLabelType='Auto'
                                      width="160" (filtering)="searchRole($event)"
                                      placeholder="Role" formControlName="role"
                                      [allowFiltering]='true' [filterBarPlaceholder]="'Search Role'"
                                      [sortOrder]="generalSortOrder" (change)="onRoleSelection($event, client, project)"
                                      [popupHeight]='height' class="role-dropdown">
                      <ng-template #noRecordsTemplate>
                        <span class='norecord'>Loading Roles...</span>
                      </ng-template>
                      <ng-template #itemTemplate="" let-data="">
                        <span [title]="data.name">{{ data.name }}</span>           
                      </ng-template>
                    </ejs-dropdownlist>
                    <mat-error class="role-dropdown" *ngIf="project.get('role').hasError('invalidRole') && project.get('role').touched">
                      <small>{{project.get('role')?.errors?.invalidRole}}</small>
                      </mat-error>

                  </div>
                  <div fxLayoutAlign="end">
                    <div fxLayout="row" *ngIf="isEdit === 'true'">
                      <button class="edit-access-button" *ngIf="clientToBeDisable == client.value.id && activeClientRole === 5"
                              [disabled]="clientToBeDisable == client.value.id"
                              (click)="openEditAccessFlyout($event, project)">
                        Edit Access
                      </button>
                      <button class="edit-access-button" *ngIf="clientToBeDisable !== client.value.id"
                              [disabled]="isEditAccessDisabled === true"
                              (click)="openEditAccessFlyout($event, project)">
                        Edit Access
                      </button>
                    </div>
                    <div fxLayout="row" *ngIf="isEdit === 'false'">
                      <button class="edit-access-button"
                              [disabled]="isEditAccessDisabled === true"
                              (click)="openEditAccessFlyout($event, project)">
                        Edit Access
                      </button>
                    </div>

                  </div>
                  <div style="border: 1px solid black" [ngClass]="{'hide-access-flyout': !!!project.value.showEditAccessFlyout}">
                    <div fxLayout="row" fxLayoutAlign="end">
                      <button class="edit-flyout-close-button" mat-icon-button (click)="closeEditAccessFlyoutWithoutSave($event, project)">
                        <mat-icon>close</mat-icon>
                      </button>
                    </div>

                    <ejs-treeview #defaultCheck [fields]='project.value.editAccessFields' [showCheckBox]='showCheckBox' [disabled]="isProvider"
                                  [checkedNodes]="project.value.leafPermissions"
                                  (nodeChecked)="nodeValueSelected($event, client, project)"
                                  formControlName="permissions"></ejs-treeview>

                    <div fxLayout="row" fxLayoutGap="3px" fxLayoutAlign="end" class="action-button-section">
                      <button data-testid="Yes" mat-raised-button cdkFocusInitial (click)="saveAndCloseEditAccessFlyout($event, client, project)">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</form>

<div fxLayout="row" fxLayoutGap="3px" fxLayoutAlign="end" class="action-button-section">
  <button data-testid="Yes" mat-raised-button color="primary" cdkFocusInitial type="submit"
          *ngIf="isEdit === 'false' && !isProvider" (click)="createNewUser()">
    Create
  </button>
  <button data-testid="Yes" mat-raised-button color="primary" cdkFocusInitial type="submit"
          *ngIf="isEdit === 'true' && !isProvider" (click)="updateUserData()">
    Update
  </button>
  <button data-testid="Yes" mat-raised-button color="primary" cdkFocusInitial type="submit"
  *ngIf="isEdit === 'false' && isProvider" (click)="createNewProvider()">
    Create Provider
  </button>
  <button data-testid="Yes" mat-raised-button color="primary" cdkFocusInitial type="submit"
          *ngIf="isEdit === 'true' && isProvider" (click)="updateProviderData()">
    Update
  </button>
  <button data-testid="No" mat-raised-button type="submit" (click)="onCancel()">Cancel</button>
</div>
