import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { ExtensionService } from '../athena-one-integration/shared/service/extension.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute, private extensionService: ExtensionService) { }

  error$: Observable<Params>

  ngOnInit(): void {
    this.error$ = this.activeRoute.queryParams
    this.extensionService.sendErrorToExt()
  }

}
