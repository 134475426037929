import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ClinicianRole, LoginType, MedicalAssistantRole } from "src/app/common/settings/settings";

export function roleValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const concurrentRoles = [ClinicianRole, MedicalAssistantRole];
      if (concurrentRoles.includes(value)) {
        return { invalidRole: 'Invalid role!' };
      }
      return null; 
    };
  }

  export function loginTypeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      
      if (value === LoginType.Password) {
        return { invalidType: 'Invalid login type!' };
      }
      return null; 
    };
}

