//  export const EndPointBaseUrl = "http://localhost:8086/api/";
// export const EndPointBaseUrl = "https://nlp01.eclat.payer-platform.com/api/";
import {environment} from 'src/environments/environment';

export const EndPointBaseUrl = environment.BACKEND_URL + "api/";
// export const EndPointBaseUrl = 'https://evaire-qa.eclat.payer-platform.com/api'

export const API_CURRENT_VERSION = "v1";
export const CommonDateFormat = "MM/dd/yyyy";
export const MaxLimitForGuidelinesDocument = 5;
export const MaxLimitForEducationsDocument = 5;
export const EPP_HB_MONITOR_TIME_INTERVAL = 2000;
export const EPP_HB_MONITOR_OFFSET_TIME = 60000;


// project config
export const BlindCoding = 1;
export const CodeDataValidation = 2;
export const VendorAudit = 3;
export const Concurrent = 4;

// role id config
export const ProductOwner = 1;
export const ClientRelationshipManager = 2;
export const SystemAdministrator = 3;
export const Admin = 4;
export const SuperUser = 5;
export const CoderRole = 6;
export const QAL1Role = 7;
export const QAL2Role = 8;
export const QAL3Role = 9;
export const ManagerRole = 10;
export const ProviderRole = 11;
export const ClinicianRole = 12;
export const MedicalAssistantRole = 13;

// role string to id mapping config
export const RoleStringToIdMapping = {
    'Product Owner': 1
}

export const ProviderRoleGroup = [ProviderRole];

// chart states
export const ChartLoaded = 1;
export const ReadyforCoding = 2;
export const CodingInProgress = 3;
export const CodingLockInProgress = 4;
export const CodingExclusionInProgress = 5;
export const CodingMasterExclusionInProgress = 6;
export const CodingRejectInProgress = 7;
export const CodingApprovedReject = 8;
export const CodingFeedbackInProgress = 9;
export const CodingCompleteAwaitingSampling = 10;
export const CodingCompleteOutputReady = 11;
export const CodingCompleteOutputGenerated = 12;
export const EditedCodingCompleteReadyforOutput = 13;
export const EditedCodingCompleteOutputGenerated = 14;
export const ReadyforL1QA = 15;
export const L1QAInProgress = 16;
export const L1QALockInProgress = 17;
export const L1QAExclusionInProgress = 18;
export const L1QAMasterExclusionInProgress = 19;
export const L1QARejectInProgress = 20;
export const L1QAApprovedReject = 21;
export const L1QAFeedbackInProgress = 22;
export const L1QACompleteAwaitingSampling = 23;
export const L1QACompleteOutputReady = 24;
export const L1QACompleteOutputGenerated = 25;
export const EditedL1CompleteReadyforOutput = 26;
export const EditedL1CompleteOutputGenerated = 27;
export const ReadyforL2QA = 28;
export const L2QAInProgress = 29;
export const L2QALockInProgress = 30;
export const L2QAExclusionInProgress = 31;
export const L2QAMasterExclusionInProgress = 32;
export const L2QARejectInProgress = 33;
export const L2QAApprovedReject = 34;
export const L2QAFeedbackInProgress = 35;
export const L2QACompleteAwaitingSampling = 36;
export const L2QACompleteOutputReady = 37;
export const L2QACompleteOutputGenerated = 38;
export const EditedL2CompleteReadyforOutput = 39;
export const EditedL2CompleteOutputGenerated = 40;
export const ReadyforL3QA = 41;
export const L3QAInProgress = 42;
export const L3QALockInProgress = 43;
export const L3QAExclusionInProgress = 44;
export const L3QAMasterExclusionInProgress = 45;
export const L3QARejectInProgress = 46;
export const L3QAApprovedReject = 47;
export const L3QACompleteOutputReady = 48;
export const L3QACompleteOutputGenerated = 49;
export const EditedL3CompleteReadyforOutput = 50;
export const EditedL3CompleteOutputGenerated = 51;
export const MasterFeedbackInProgress = 52;
export const FeedbackCompleteOutputReady = 53;
export const FeedbackCompleteOutputGenerated = 54;
export const NoHccCodingCompleteAwaitingSampling = 55;
export const NoHccCodingCompleteOutputReady = 56;
export const NoHccCodingCompleteOutputGenerated = 57;
export const NoHccReadyForL1QA = 58;
export const NoHccL1QAInProgress = 59;
export const NoHccL1QALockInProgress = 60;
export const NoHccL1QAExclusionInProgress = 61;
export const NoHccL1QAMasterExclusionInProgress = 62;
export const NoHccL1QARejectInProgress = 63;
export const NoHccL1QAApprovedReject = 64;
export const NoHccL1QACompleteAwaitingSampling = 65;
export const NoHccL1QACompleteOutputReady = 66;
export const NoHccL1QACompleteOutputGenerated = 67;
export const NoHccEditedL1QACompleteReadyForOutput = 68;
export const NoHccEditedL1QACompleteOutputGenerated = 69;
export const NoHccReadyForL2QA = 70;
export const NoHccL2QAInProgress = 71;
export const NoHccL2QALockInProgress = 72;
export const NoHccL2QAExclusionInProgress = 73;
export const NoHccL2QAMasterExclusionInProgress = 74;
export const NoHccL2QARejectInProgress = 75;
export const NoHccL2QAApprovedReject = 76;
export const NoHccL2QACompleteAwaitingSampling = 77;
export const NoHccL2QACompleteOutputReady = 78;
export const NoHccL2QACompleteOutputGenerated = 79;
export const NoHccEditedL2QACompleteReadyForOutput = 80;
export const NoHccEditedL2QACompleteOutputGenerated = 81;
export const NoHccReadyForL3QA = 82;
export const NoHccL3QAInProgress = 83;
export const NoHccL3QALockInProgress = 84;
export const NoHccL3QAExclusionInProgress = 85;
export const NoHccL3QAMasterExclusionInProgress = 86;
export const NoHccL3QARejectInProgress = 87;
export const NoHccL3QAApprovedReject = 88;
export const NoHccL3QACompleteOutputReady = 89;
export const NoHccL3QACompleteOutputGenerated = 90;
export const NoHccEditedL3QACompleteReadyForOutput = 91;
export const NoHccEditedL3QACompleteOutputGenerated = 92;
export const NoHccEditedCodingCompleteOutputReady = 93;
export const NoHccEditedCodingCompleteOutputGenerated = 94;
export const EditedFeedbackCompleteOutputReady = 95;
export const EditedFeedbackCompleteOutputGenerated = 96;
export const Provider_Decision_Inprogress = 97;
export const Awaiting_provider_response_coder_pk = 98;
export const awaiting_provider_response_l1_pk = 99;
export const awaiting_provider_response_l2_pk = 100;
export const awaiting_provider_response_l3_pk = 101;
export const provider_decision_complete_coder_pk = 102;
export const provider_decision_complete_l1_pk = 103;
export const provider_decision_complete_l2_pk = 104;
export const provider_decision_complete_l3_pk = 105;
export const provider_decision_complete_pk = 1006;

// resource id config
export const LockQueuesResource = -1;
export const CodingReviewResource = 1;
export const L1AuditResource = 2;
export const L2AuditResource = 3;
export const L3AuditResource = 4;
export const LicenseManagementResource = 5;
export const ReportingResource = 6;
export const StandardReportsResource = 7;
export const CustomReportsResource = 8;
export const MasterQueueResource = 9;
export const ExclusionQueuesResource = 10;
export const CoderChartsExclusionResource = 11;
export const L1QAChartsExclusionResource = 12;
export const L2QAChartsExclusionResource = 13;
export const L3QAChartsExclusionResource = 14;
export const FeedbackQueuesResource = 15;
export const CoderErrorFeedbackResource = 16;
export const L1QAErrorFeedbackResource = 17;
export const L2QAErrorFeedbackResource = 18;
export const L3QAErrorFeedbackResource = 19;
export const RejectQueuesResource = 20;
export const DashboardResource = 21;
export const ExecutiveDashboardResource = 22;
export const ManagerDashboardResource = 23;
export const ProviderDashboardResource = 24;
export const ChartStatusResource = 25;
export const AdminResource = 26;
export const UserManagementResource = 27;
export const ProviderResource = 28;
export const GuidelineResource = 29;
export const EducationResource = 30;
export const AlertsResource = 31;
export const HelpDesk = 32;
export const MailboxResource = 33;
export const MiscResource = 34;
export const ChartRepositoryResource = 35;
export const PartialAccessResource = 36;
export const CompleteAccessResource = 37;
export const CodingProfileResource = 38;
export const ResetFunctionalityResource = 39;
export const EditChartResource = 40;
export const SamplingManagementResource = 41;
export const WorkQueueManagementResource = 42;
export const SuperUserAccessResource = 44;
export const ManagerReportsResource = 46;
//in concurrent/develop
// export const ManagerReportsResource = 48;
export const ProviderQueriesResource = 50;
export const ProvidersDashboardResource = 47;
export const Non_EHR_Dashboard = 49

// export const HccProfileValidated = 1;
// export const HccProfileAgree = 5;
// export const HccProfileAdd = 1;
// export const HccProfileAddAsr = 0;
// export const HccProfileChange = 3;
// export const HccProfileRemove = 2;
// export const HccProfileNotValidated = 2;

export const StatusCategoryValidated = 1;
export const StatusCategoryAgree = 2;
export const StatusCategoryAdd = 3;
export const StatusCategoryAddAsr = 4;
export const StatusCategoryChange = 5;
export const StatusCategoryRemove = 6;
export const StatusCategoryNotValidated = 7;
export const StatusCategoryQuery = 8;

export const FinalCategoryValidated = 1;
export const FinalCategoryAgree = 1;
export const FinalCategoryAdd = 3;
export const FinalCategoryAddAsr = 4;
export const FinalCategoryChange = 5;
export const FinalCategoryRemove = 6;
export const FinalCategoryNotValidated = 6;


export const TargetedHccWorkQueueCriteria = 1;
export const PdfSizeWorkQueueCriteria = 2;
export const PrioritizedProviderWorkQueueCriteria = 3;
export const FifoWorkQueueCriteria = 4;

export const ChartSamplingCriteria = 1;
export const ReviewerSamplingCriteria = 2;
export const AccuracyRateSamplingCriteria = 3;
export const HccSamplingCriteria = 4;
export const IcdSamplingCriteria = 5;
export const CodeStatusSamplingCriteria = 6;
export const ProjectQuerySamplingCriteria = 7;
export const CoderQuerySamplingCriteria = 8;

export const ReviewerResponseAccept = 1;
export const ReviewerResponseDisagree = 2;
export const FinalStatusUpheld = 3;
export const FinalStatusOverTurned = 4;

// report id config
export const LockedAgingReport = 1;
export const NoHCCCodeReport = 2;
export const RejectChartReport = 3;
export const CodingResultReport = 4;
export const ExclusionChartAgingReport = 5;
export const FeedbackAgingReport = 6;
export const HCCAnalysisReport = 7;
export const ChartsInQueue = 8;
export const QueryStatusReport = 9;
export const QueryTrending = 10;
export const ProviderResponse = 11;
export const MasterProductivityReport = 12;
export const AccuracyReport = 13;
export const ManagerDashboard = 14;
export const ProviderDashboard = 15;
export const ExecutiveDashboard = 16;
export const UserProductivityReport = 17;

export const SessionExpiredTime = 1500; // seconds
export const SessionExtendTime = 1740; // seconds
export const SessionAutoLogOutTime = 300000; // milliseconds

export const dateViewFormat = "MM/dd/yyyy";

export const AthenaSnomedStatus = {
    CouldNotBePosted: -1,
    NoSnomedCodeToPost: 0,
    PostedSuccessfully: 1,
    ICDAddedToExisting: 2,
    ICDExistsNotPosted: 3
}

export const AthenaResetChartEvents = {
    Success: 'chart-reset-successful',
    Error: 'chart-reset-failed'
}

export const CommonAlertStatus = {
    Cancelled: 1,
    Scheduled: 2,
    Complete: 3,
    Active: 4
}

export const CommonResources = {
    'ProviderQueries': {
      "id": 50,
      "name": "Provider Queries",
      "status": "1",
      "subResources": []
    }
}

export const LoginType = {
    SSO: 0,
    Password: 1
}

export const ExtensionRoles = {
    [ProviderRole]: 'provider',
    [ClinicianRole]: 'cdi-reviewer',
    [MedicalAssistantRole]: 'medical-assistant'
}